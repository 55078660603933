import React from 'react'

export const B3400 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1407"
    height="913"
    viewBox="0 0 1407 913"
    {...props}
  >
    <defs>
      <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 256 246">
        <image width="256" height="246" href="../b3-400/B3-400-PA01.351.13E.1701_0005_Camada-8.png" />
      </pattern>
      <pattern id="pattern-2" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 221 309">
        <image width="221" height="309" href="../b3-400/B3-400-PA01.351.13E.1701_0004_Camada-7.png" />
      </pattern>
      <pattern id="pattern-3" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 126 157">
        <image width="126" height="157" href="../b3-400/B3-400-PA01.351.13E.1701_0000_Camada-3.png" />
      </pattern>
      <pattern id="pattern-4" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 78 107">
        <image width="78" height="107" href="../b3-400/B3-400-PA01.351.13E.1701_0001_Camada-4.png" />
      </pattern>
      <pattern id="pattern-5" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 34 26">
        <image width="34" height="26" href="../b3-400/B3-400-PA01.351.13E.1701_0002_Camada-5.png" />
      </pattern>
      <pattern id="pattern-6" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 28 31">
        <image width="28" height="31" href="../b3-400/B3-400-PA01.351.13E.1701_0006_Camada-9.png" />
      </pattern>
      <pattern id="pattern-7" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 123 68">
        <image width="123" height="68" href="../b3-400/B3-400-PA01.351.13E.1701_0007_Camada-10.png" />
      </pattern>
      <pattern id="pattern-8" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 23 27">
        <image width="23" height="27" href="../b3-400/B3-400-PA01.351.13E.1701_0008_Camada-11.png" />
      </pattern>
      <pattern id="pattern-9" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 31 34">
        <image width="31" height="34" href="../b3-400/B3-400-PA01.351.13E.1701_0009_Camada-12.png" />
      </pattern>
      <pattern id="pattern-10" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 70 49">
        <image width="70" height="49" href="../b3-400/B3-400-PA01.351.13E.1701_0011_Camada-14.png" />
      </pattern>
      <pattern id="pattern-11" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 87 98">
        <image width="87" height="98" href="../b3-400/B3-400-PA01.351.13E.1701_0012_Camada-15.png" />
      </pattern>
      <pattern id="pattern-12" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 95 111">
        <image width="95" height="111" href="../b3-400/B3-400-PA01.351.13E.1701_0013_Camada-16.png" />
      </pattern>
      <pattern id="pattern-13" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 135 112">
        <image width="135" height="112" href="../b3-400/B3-400-PA01.351.13E.1701_0014_Camada-17.png" />
      </pattern>
      <pattern id="pattern-14" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 97 91">
        <image width="97" height="91" href="../b3-400/B3-400-PA01.351.13E.1701_0016_Camada-19.png" />
      </pattern>
      <pattern id="pattern-15" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 46 30">
        <image width="46" height="30" href="../b3-400/B3-400-PA01.351.13E.1701_0018_Camada-21.png" />
      </pattern>
      <pattern id="pattern-16" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 40 49">
        <image width="40" height="49" href="../b3-400/B3-400-PA01.351.13E.1701_0017_Camada-20.png" />
      </pattern>
      <pattern id="pattern-17" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 107 161">
        <image width="107" height="161" href="../b3-400/B3-400-PA01.351.13E.1701_0019_Camada-22.png" />
      </pattern>
      <pattern id="pattern-18" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 15 32">
        <image width="15" height="32" href="../b3-400/B3-400-PA01.351.13E.1701_0021_Camada-24.png" />
      </pattern>
      <pattern id="pattern-19" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 174 74">
        <image width="174" height="74" href="../b3-400/B3-400-PA01.351.13E.1701_0022_Camada-25.png" />
      </pattern>
    </defs>
    <g id="Grupo_92" data-name="Grupo 92" transform="translate(-203 -83)"
      className="vista-g">
      <g id="Grupo_91" data-name="Grupo 91">
        <rect id="B3-400-PA01.351.13E.1701_0005_Camada-8" width="270" height="259" transform="translate(504 246)" fill="url(#pattern)" />
        <rect id="B3-400-PA01.351.13E.1701_0004_Camada-7" width="233" height="325" transform="translate(203 388)" fill="url(#pattern-2)"
          data-name="Cremalheira de Ferro Fundido 350-400-410"
          data-url="https://shopirbal.com/pt/produto/cremalheira-de-ferro-fundido-350-400-410/" />
        <rect id="B3-400-PA01.351.13E.1701_0000_Camada-3" width="133" height="165" transform="translate(253 775)" fill="url(#pattern-3)" />
        <rect id="B3-400-PA01.351.13E.1701_0001_Camada-4" width="82" height="113" transform="translate(448 784)" fill="url(#pattern-4)" />
        <rect id="B3-400-PA01.351.13E.1701_0002_Camada-5" width="35" height="27" transform="translate(558 802)" fill="url(#pattern-5)" />
        <image id="B3-400-PA01.351.13E.1701_0003_Camada-6" width="95" height="115" transform="translate(614 690)" href="../b3-400/B3-400-PA01.351.13E.1701_0003_Camada-6.png" />
        <image id="B3-400-PA01.351.13E.1701_0010_Camada-13" width="420" height="497" transform="translate(771 411)" href="../b3-400/B3-400-PA01.351.13E.1701_0010_Camada-13.png" />
        <rect id="B3-400-PA01.351.13E.1701_0006_Camada-9" width="30" height="33" transform="translate(719 622)" fill="url(#pattern-6)" />
        <rect id="B3-400-PA01.351.13E.1701_0007_Camada-10" width="129" height="72" transform="translate(783 467)" fill="url(#pattern-7)"
          data-name="Rolamento do Cubo do Balde RL 32011 X"
          data-url="https://shopirbal.com/pt/produto/rolamento-do-cubo-do-balde-rl-32011-x/" />
        <rect id="B3-400-PA01.351.13E.1701_0008_Camada-11" width="24" height="28" transform="translate(946 503)" fill="url(#pattern-8)" />
        <rect id="B3-400-PA01.351.13E.1701_0009_Camada-12" width="32" height="36" transform="translate(870 658)" fill="url(#pattern-9)"
          data-name="Pinhão/Carreto B3 + CAVILHA"
          data-url="https://shopirbal.com/pt/produto/pinhao-carreto-b3-cavilha/" />

        <rect id="B3-400-PA01.351.13E.1701_0011_Camada-14" width="74" height="52" transform="translate(1079 324)" fill="url(#pattern-10)"
          data-name="Rolamento do Lado do Pinhão RL 6205 2RS"
          data-url="https://shopirbal.com/pt/produto/rolamento-rl-6205-2rs/" />
        <rect id="B3-400-PA01.351.13E.1701_0012_Camada-15" width="92" height="103" transform="translate(1192 383)" fill="url(#pattern-11)" />
        <rect id="B3-400-PA01.351.13E.1701_0013_Camada-16" width="100" height="117" transform="translate(1300 801)" fill="url(#pattern-12)"
          data-name="Roda Maciça Ø400"
          data-url="https://shopirbal.com/pt/produto/roda-macica-400/" />
        <rect id="B3-400-PA01.351.13E.1701_0014_Camada-17" width="142" height="118" transform="translate(1280 667)" fill="url(#pattern-13)" />
        <image id="B3-400-PA01.351.13E.1701_0015_Camada-18" width="80" height="155" transform="translate(1352 497)" href="../b3-400/B3-400-PA01.351.13E.1701_0015_Camada-18.png"
          data-name="Correia PV 10PJ 1355"
          data-url="https://shopirbal.com/pt/produto/correia-pv-10pj-1355/" />
        <rect id="B3-400-PA01.351.13E.1701_0016_Camada-19" width="102" height="96" transform="translate(1552 526)" fill="url(#pattern-14)"
          data-name="Motor ELE 3,00HP – 50HZ – 220V"
          data-url="https://shopirbal.com/pt/produto/motor-ele-300hp-50hz-220v/" />
        <rect id="B3-400-PA01.351.13E.1701_0018_Camada-21" width="49" height="32" transform="translate(1618 675)" fill="url(#pattern-15)"
          data-name="Condensador 2HP"
          data-url="https://shopirbal.com/pt/produto/condensador-2hp/" />
        <rect id="B3-400-PA01.351.13E.1701_0017_Camada-20" width="42" height="52" transform="translate(1540 770)" fill="url(#pattern-16)"
          data-name="Interruptor B3+B4"
          data-url="https://shopirbal.com/pt/produto/interruptor-b3b4/" />
        <rect id="B3-400-PA01.351.13E.1701_0019_Camada-22" width="113" height="169" transform="translate(1279 279)" fill="url(#pattern-17)" />
        <image id="B3-400-PA01.351.13E.1701_0020_Camada-23" width="180" height="280" transform="translate(1409 188)" href="../b3-400/B3-400-PA01.351.13E.1701_0020_Camada-23.png" />
        <rect id="B3-400-PA01.351.13E.1701_0021_Camada-24" width="15" height="34" transform="translate(1635 209)" fill="url(#pattern-18)" />
        <rect id="B3-400-PA01.351.13E.1701_0022_Camada-25" width="183" height="78" transform="translate(1411 83)" fill="url(#pattern-19)" />
      </g>
    </g>
  </svg>
)
